import inherits from 'inherits-browser';

import PaletteProvider from 'bpmn-js/lib/features/palette/PaletteProvider';

import get from '../importer/index.js';

function getImageSize(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
}

export default function CustomPaletteProvider(
  palette,
  create,
  elementFactory,
  spaceTool,
  lassoTool,
  handTool,
  globalConnect,
  translate,
) {
  PaletteProvider.call(
    this,
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
    globalConnect,
    translate,
  );

  this._create = create;
  this._elementFactory = elementFactory;
  this.translate = translate;
}

inherits(CustomPaletteProvider, PaletteProvider);

const data = await get;

CustomPaletteProvider.$inject = [
  'palette',
  'create',
  'elementFactory',
  'spaceTool',
  'lassoTool',
  'handTool',
  'globalConnect',
  'translate',
];

CustomPaletteProvider.prototype.getPaletteEntries = function (element) {
  var actions = PaletteProvider.prototype.getPaletteEntries.call(this, element);
  var create = this._create;
  var elementFactory = this._elementFactory;

  function createAction(type, group, title, base64, initialHeight, initialWidth, options = {}, enabled, family = null) {
    async function createListener(event) {

  /*     const size = await getImageSize(url);
      const aspectRatio = size.width / size.height;
      const height = initialWidth/aspectRatio; */

      var shape = elementFactory.createShape(
        Object.assign({ type: type, title: title, base64: base64, group: group, width: initialWidth, height: initialHeight, init: true }, options),
      );
      create.start(event, shape);
    }

    return {
      group: group,
      className: title,
      title: title,
      action: {
        dragstart: createListener,
        click: createListener,
      },
      'enabled': enabled,
      'family': family
    };
  }

  function createSeparator() {
    return {
      group: 'separator',
      separator: true
    };
  }
  
  actions['separator'] = createSeparator();

  data.images.forEach((element) => {
    actions[`create.custom:${element.title}`] = createAction(
      'bpmn:IntermediateThrowEvent',
      'image',
      element.title,
      element.base64,
      element.initialWidth / element.aspectRatio || 150 / element.aspectRatio,
      element.initialWidth || 150,
      {},
      element.enabled,
      element.family
    );
  });

  // Adiciona um separador antes dos eventos

  actions['create.link'] = createAction(
    'bpmn:DataObjectReference',
    'event',
    'Link Event',
    'https://www.svgrepo.com/show/529680/link.svg',
    54,
    54,
    data.palette['create.link'].enabled,
    { link: true }
  );

  return actions;
};
