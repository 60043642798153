import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';
import data from '../utils/jsonData.js';
import inherits from 'inherits-browser';


export default function ResizeAllRules(eventBus) {
  RuleProvider.call(this, eventBus);
}

inherits(ResizeAllRules, RuleProvider);

ResizeAllRules.$inject = [ 'eventBus' ];

ResizeAllRules.prototype.init = function() {
  this.addRule('shape.resize', 1500, function(context) {
    const shape = context.shape;
    const newBounds = context.newBounds;
    const originalWidth = shape.width;
    const originalHeight = shape.height;
    const aspectRatio = originalWidth / originalHeight;

    if (newBounds) {
      const newHeight = newBounds.height;
      const newWidth = newHeight * aspectRatio;

      // Determine the resize direction
      const deltaX = newBounds.x - shape.x;
      const deltaY = newBounds.y - shape.y;

      // Update the bounds to lock the position opposite to the resize direction
      if (deltaX !== 0) {
        newBounds.x = shape.x + (shape.width - newWidth);
      }
      if (deltaY !== 0) {
        newBounds.y = shape.y + (shape.height - newHeight);
      }

      newBounds.width = newWidth;
      newBounds.height = newHeight;

      var temp_data = data.json_object;

      temp_data.elements.forEach((element) => {
        if (element.id === shape.id) {
          element.width = newBounds.width;
          element.height = newBounds.height;
        }
      });

      return newBounds;
    } else {
      return (shape.url || shape.base64) && shape.title !== 'Link Event';
    }
  });

};