/* eslint-disable indent */

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';

import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

import './style.css';

import $, { event } from 'jquery';

import colorPicker from './color-picker/index.js';
import BpmnModeler from 'bpmn-js/lib/Modeler';

import diagramXML from '../resources/newDiagram.bpmn';

import ResizeAllRules from './resize-all-rules/index.js';
import CustomPalette from './palette-provider/index.js';
import CustomRender from './draw/index.js';
import get from './importer/index.js';

import shapeAdded from './utils/shapeAdded.js';
import data from './utils/jsonData.js';
import applyJsonModifications from './utils/applyJsonModifications.js';

var jsonData = {};

var container = $('#js-drop-zone');

var modeler = new BpmnModeler({
  container: '#js-canvas',
  keyboard: {
    bindTo: window,
  },
  additionalModules: [ CustomPalette, ResizeAllRules, CustomRender, colorPicker ],

  // bpmnRenderer: {
  //  defaultFillColor: '#1f1f1f',
  //  defaultStrokeColor: '#fff',
  // }, 
});

var eventBus = modeler.get('eventBus');

eventBus.on('shape.added', async (event) => {
  jsonData = await get;
  shapeAdded(event, modeler, jsonData);
});
  
eventBus.on('shape.remove', async (event) => {
  let temp_data = data.json_object; 
  if (temp_data.elements) {
    temp_data.elements = temp_data.elements.filter((element) => element.id !== event.element.id);
    data.setData(temp_data);
  }
});

function createNewDiagram() {
  openDiagram(diagramXML);
}

async function openDiagram(xml) {
  try {
    await modeler.importXML(xml);

    container.removeClass('with-error').addClass('with-diagram');

    jsonData = await get;

    applyJsonModifications(modeler, jsonData);
  } catch (err) {
    container.removeClass('with-diagram').addClass('with-error');

    container.find('.error pre').text(err.message);

    console.error(err);
  }
}

function registerFileDrop(container, callback) {
  function handleFileSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    var files = e.dataTransfer.files;

    var file = files[0];

    var reader = new FileReader();

    reader.onload = function(e) {
      var xml = e.target.result;

      callback(xml);
    };

    reader.readAsText(file);
  }

  function handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }

  container.get(0).addEventListener('dragover', handleDragOver, false);
  container.get(0).addEventListener('drop', handleFileSelect, false);
}

document
  .getElementById('js-import-diagram')
  .addEventListener('click', function() {
    const fileInput = document.getElementById('js-file-input');
    fileInput.click(); // Abre a janela para escolher o arquivo

    fileInput.addEventListener(
      'change',
      function(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function(e) {
            const xml = e.target.result;
            openDiagram(xml); // Chama a função para importar o diagrama
          };
          reader.readAsText(file);
        } else {
          alert('Por favor, selecione um arquivo BPMN válido.');
        }
      },
      { once: true },
    ); // Garante que o evento seja acionado apenas uma vez
  });

// file drag / drop ///////////////////////

// check file api availability
if (!window.FileList || !window.FileReader) {
  window.alert(
    'Looks like you use an older browser that does not support drag and drop. ' +
      'Try using Chrome, Firefox or the Internet Explorer > 10.',
  );
} else {
  registerFileDrop(container, openDiagram);
}

createNewDiagram();

$(function() {

  $('#js-create-diagram').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    createNewDiagram();
  });

  var downloadLink = $('#js-download-diagram');
  var downloadSvgLink = $('#js-download-svg');

  $('.buttons a').click(function(e) {
    if (!$(this).is('.active')) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  function setEncoded(link, name, data) {
    var encodedData = encodeURIComponent(data);

    if (data) {
      link.addClass('active').attr({
        'href': 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData,
        'download': name
      });
    } else {
      link.removeClass('active');
    }
  }

  let removedElements = [];

  function removeDottedHoverElements() {
    const dottedHoverElements = document.querySelectorAll('.dotted-hover');
    const lineHoverElements = document.querySelectorAll('.line-selected');
    removedElements = [];
    dottedHoverElements.forEach(element => {
      removedElements.push({
        element: element.cloneNode(true),
        parent: element.parentNode
      });
      element.remove();
    });
    lineHoverElements.forEach(element => {
      removedElements.push({
        element: element.cloneNode(true),
        parent: element.parentNode
      });
      element.remove();
    });
  }

  function restoreDottedHoverElements() {
    removedElements.forEach(({ element, parent }) => {
      parent.appendChild(element);
    });
    removedElements = [];
  }

  var exportArtifacts = debounce(async function() {

    try {

      removeDottedHoverElements();
      const { svg } = await modeler.saveSVG();

      setEncoded(downloadSvgLink, 'diagram.svg', svg);
    } catch (err) {

      console.error('Error happened saving svg: ', err);
      setEncoded(downloadSvgLink, 'diagram.svg', null);
    } finally {
      restoreDottedHoverElements();
    }

    try {

      const { xml } = await modeler.saveXML({ format: true });

      setEncoded(downloadLink, 'diagram.bpmn', xml);
    } catch (err) {

      console.error('Error happened saving XML: ', err);
      setEncoded(downloadLink, 'diagram.bpmn', null);
    }
  }, 500);

  modeler.on('commandStack.changed', exportArtifacts);
});



// helpers //////////////////////

function debounce(fn, timeout) {
  var timer;

  return function() {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(fn, timeout);
  };
}

const palette = document.querySelector('.djs-palette');

var zoomScroll = modeler.get('zoomScroll');

if(palette) {
  palette.addEventListener('mouseover', (e) => {
    zoomScroll.toggle(false);
  });
  palette.addEventListener('mouseout', (e) => {
    zoomScroll.toggle(true);
  });
} 


function assignFunctionToElement(modeler, elementId, eventType, func) {
  const elementRegistry = modeler.get('elementRegistry');
  const eventBus = modeler.get('eventBus');
  const target_element = elementRegistry.get(elementId);
  const modeling = modeler.get('modeling');

  if (!target_element) {
    console.error(`Elemento com ID ${elementId} não encontrado.`);
    return;
  }

  function eventFunction(e, modeling, diagram_element) {
    e.preventDefault();
    let element = e.element;
    function updateText(text) {
      modeling.updateLabel(element, text);
    }
    function updateImage(image) {
      e.element.url = image;
      modeling.updateProperties(e.element, {});
    }
    if (e.element.id === diagram_element.id) {
      func();
    }
  }


  // Adiciona a função ao elemento
  eventBus.on(
    eventType,
    900,
    async (e) => eventFunction(e, modeling, target_element),
    { passive: true },
  );
}

window.modeler = modeler;
window.assignFunctionToElement = assignFunctionToElement;


// Modo de edição e visualização
let isEditMode = true;
const dragging = modeler.get('dragging');


function toggleMode() {
  isEditMode = !isEditMode;

  const palette = document.querySelector('.djs-palette');
  const resizableElements = document.querySelector('.layer-resizers');
  const editableElements = document.querySelectorAll('.djs-editable');
  const elements = document.querySelectorAll('.djs-hit')
  const pad = document.querySelector('.djs-context-pad-parent');

  if (isEditMode) {
    // Ativar modo de edição
    if (palette) palette.style.display = 'block';
    resizableElements.style.display = 'block';
    editableElements.forEach(el => el.contentEditable = 'true');
    pad.style.display = 'block';
    dragging.setOptions({ manual: false });

  } else {
    // Ativar modo de visualização
    if (palette) palette.style.display = 'none';
    resizableElements.style.display = 'none';
    editableElements.forEach(el => el.contentEditable = 'false');
    pad.style.display = 'none';

    dragging.setOptions({ manual: true });
  }
}


document.getElementById('toggle-mode').addEventListener('click', toggleMode);

// filter

if (palette) {
  let filter = document.createElement('select');
  let families = [];
  let json_data = await get;
  filter.style.width = '90%';
  filter.style.padding = '5px';
  filter.style.height = '30px';
  filter.style.borderRadius = '8px';
  filter.style.marginBottom = '10px';
  filter.style.margin = '5px';
  filter.classList.add('filter');

  let option = document.createElement('option');
      option.value = 'none';
      option.text= 'Filtrar';
      filter.appendChild(option);


  json_data.images.forEach((image) => {
    if (image.family && !families.includes(image.family)) {
      families.push(image.family);
      let option = document.createElement('option');
      option.value = image.family;
      option.text = image.family;
      filter.appendChild(option);
    }
  });

  const customPalette = modeler.get('paletteProvider');
  const paletteEntries = customPalette.getPaletteEntries();

  Object.keys(paletteEntries).forEach((element) => {
    if (json_data.palette[element] && !json_data.palette[element].enabled) {
      paletteEntries[element].enabled = false;
    } else if (json_data.palette[element] )
      paletteEntries[element].enabled = true;
  });

  filter.addEventListener('change', (e) => {
    let value = e.target.value;
    let elements = document.querySelectorAll('.djs-palette .entry');
  
    elements.forEach((element) => {
      let entry = paletteEntries[element.dataset.action];
      console.log(entry);
      if (entry.group === 'image') {
        if ((value === 'none') || (entry && entry.family === value)) {
          if (entry.enabled) element.style.display = 'block';
        } else {
          element.style.display = 'none';
        }
      }
    }); 
  });
  
  palette.insertBefore(filter, palette.firstChild);
}


